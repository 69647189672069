<template>
  <div>
    <v-container class="fill-heigt pt-0" fluid>
       <v-row v-if="currentUser.candidate.province===null">
        <v-col cols="12" md="12" class="text-center">
          <v-alert
            color="#fdf0d7"
            dense
            type="info"
            class="text-center black--text caption mb-3"
            dismissible
            ><span v-html="$t('candidate_complete')" @click="go()"></span
          ></v-alert>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" md="8" class="text-left px-2 mt-1">
          <v-row>
            <!-- <a class="text-left black--text px-2 pt-7"  >
              {{ $t("Job_Openings") }}
            </a> -->
            <div class="text-left black--text px-0 pt-7 text-decoration-none">
              <v-btn
                color="primary"
                text
                small
                class="text-capitalize"
                @click="$emit('viewExpo')"
              >
                <span class="body-2 black--text">{{ $t("Job_Openings") }}</span>
              </v-btn>
            </div>
            <div class="text-left black--text px-0 pt-7 text-decoration-none">
              <v-btn
                color="primary"
                style="background: #fdf0d7"
                text
                small
                class="text-capitalize"
              >
                <span class="body-2 black--text">{{
                  $t("spontaneou_application")
                }}</span>
              </v-btn>
            </div>
          </v-row>
        </v-col>

        <v-col cols="12" md="4" class="text-right px-0 mt-2">
          <v-row>
            <!-- <div class="px-0 pt-3">
              <vacancy-Filter
                @apply="applyVacancyFilter"
                @clean="applyVacancyFilter"
              />
            </div> -->
            <v-text-field
              class="px-2 pt-3"
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('search_company')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-row>
        </v-col> </v-row
    ></v-container>

    <v-divider class="my-1"></v-divider>
    <!-- <pre>
      {{ candidancy }}
    </pre> -->
    <v-row class="">
      <v-col cols="12" md="9">
        <v-radio-group v-model="jobType" row hide-details class="mt-0">
          <v-radio :label="$t('job')" :value="'job'" hide-details></v-radio>
          <v-radio
            :label="$t('Internship')"
            :value="'Internship'"
            hide-details
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="3" class="text-right"> </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="4"
        v-for="entity in filteredItems"
        :key="entity"
      >
        <v-card class="mx-auto" max-height="220">
          <v-card-text>
            <v-row justify="space-around" class="pa-7 mx-auto">
              <v-avatar color="secondary">
                <img
                  v-if="entity.logo"
                  :src="`${apiUrl}/images/entity/${entity.logo}`"
                  alt="John"
                />
                <v-icon dark v-else> mdi-home </v-icon>
              </v-avatar>
              <v-col cols="12" md="12">
                <p class="text-center">{{ entity.name }}</p>
                <p
                  class="text-center black--text"
                  v-if="entity.address"
                  style="margin-top: -8%"
                >
                  {{ entity.address.substring(0, 30) }}
                </p>
                <p v-else v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></p>
                <v-row justify="space-around">
                  <!-- <pre>
                    {{ !entity.status }}
                  </pre> -->
                  <v-btn
                    color="primary"
                    :disabled="
                      indexDisebla.findIndex((i) => i === entity.id) > -1
                    "
                    :loading="!entity.status"
                    class="mx-0 pr-4"
                    @click="sendCandidancy(entity)"
                  >
                    <span class="black--text">{{$t('sendaplication')}}</span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <alert-dialog
      :dialog="dialogAlert"
      :message="completeMessage"
      @close="dialogAlert = false"
      @go="go()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statusVacancyMixins } from "@/mixins/status";
import { API_URL } from "@/api";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import { GET_ENTITY_QUERY, GET_UNSOLICITED_ENTITY } from "../graphql/Query";
import { SEND_UNSOLICITED_CANDIDANCY } from "../graphql/Mutation";
import AlertDialog from "../../../../entity/components/AlertDialog.vue";
import moment from "moment";
export default {
  components: { SuccessDialog, AlertDialog },
  name: "Home",
  data: () => ({
    apiUrl: API_URL,
    isLoading: false,
    loadingData: true,
    entities: [],
    indexDisebla: [],
    showSuccess: false,
    textSearch: undefined,
    jobType: "job",
    candidancy: {
      jobType: undefined,
      entityId: undefined,
      candidate: undefined,
    },
    success: undefined,
    dialogAlert: false,
    completeMessage: "",
  }),
  mixins: [statusVacancyMixins, replaceSpecialCharsMixins],
  apollo: {
    entities: {
      query: GET_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
    getUnsolicitedEntities: {
      query: GET_UNSOLICITED_ENTITY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.getUnsolicitedEntities) {
          this.loadingData = false;
          data.getUnsolicitedEntities.forEach((element) => {
            this.indexDisebla.push(element.entityId);
          });
        }
      },
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    filteredItems() {
      let resultItems = [];
      // if (this.indexDisebla.length > 0) {
      resultItems = this.entities;
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.name).includes(text)
        );
      }
      // }
      return resultItems;
    },
  },

  methods: {
    view(vacancy) {
      this.$emit("view", vacancy);
    },
    async sendCandidancy(entity) {
      if ( this.currentUser.candidate.status && this.currentUser.candidate.province) {
        this.isLoading = true;
        this.loadingData = true;
         entity.status = false;
        try {
          await this.$apollo.mutate({
            mutation: SEND_UNSOLICITED_CANDIDANCY,
            variables: {
              unsolicitedInput: {
                jobType: this.jobType,
                date: moment(),
                entityId: entity.id,
              },
            },
          });
          this.indexDisebla.push(entity.id);
          this.showSuccess = true;
          entity.status = true;
          if (this.jobType === "job") {
            this.success = this.$t("send_job");
          } else {
            this.success = this.$t("send_Internship");
          }
        } catch (error) {
          // this.error = formatError(error.message);
          console.log(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.dialogAlert = true;
        if (this.currentUser.candidate.status) {
          if (this.currentUser.candidate.province === null) {
            this.completeMessage = this.$t("complete_profile").replace(
              "province",
              this.$t("complete_profile_province")
            );
          }
        } else {
          this.completeMessage = this.$t("complete_profile").replace(
            "province",
            ""
          );
        }
      }
    },
    go() {
      this.$router.push({
        path: `/candidate/candidate/profile`,
      });
    },
  },
  // created () {

  // }
};
</script>